import styled from "styled-components"

import { AxiosError } from "axios"

import { AdjustableLocationMap } from "src/components/Homes/HomeAddress/AdjustableHomeMap"
import { ILocation } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function StepHomeGroupMap({
  location,
  onChangeLocation,
  error,
}: {
  location?: ILocation
  onChangeLocation: (location: ILocation) => void
  error?: AxiosError | null
}) {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.XS2}>
        {t(langKeys.home_address_wizard_map_title)}
      </MText>

      <MText variant="body" marginBottom={spacing.XL}>
        {t(langKeys.home_address_wizard_map_description)}
      </MText>

      <AdjustableLocationMap
        location={location}
        onChangeLocation={onChangeLocation}
      />

      {!!error && (
        <Alert type="error" fullWidth>
          {t(langKeys.failed_something_went_wrong)}
        </Alert>
      )}
    </div>
  )
}

const Alert = styled(MBanner)`
  margin-top: ${spacing.M};
`
